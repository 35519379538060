import { ModalBase } from "../modal_base";
import { Sortable, MultiDrag } from 'sortablejs';
import {AjaxSync} from "../../shared/ajax_utilities";

export class ListSettingsModal extends ModalBase {
    INITIAL_ASSIGNED_STATE;
    INITIAL_HIDDEN_STATE;

    constructor() {
        super('list_settings_modal');
        this.INITIAL_ASSIGNED_STATE = this.MODAL_CONTAINER_EL.find('#default_assigned').html();
        this.INITIAL_HIDDEN_STATE = this.MODAL_CONTAINER_EL.find('#default_hidden').html();
        Sortable.mount(new MultiDrag());
    }

    setupModalView($modalTriggerBtn, additionalData) {
        new Sortable(this.MODAL_CONTAINER_EL.find('#assigned').get(0), {
            group: 'shared',
            preventOnFilter: true,
            animation: 150,
            multiDrag: true,
            selectedClass: "sortable-selected", // Class name for selected item
            multiDragKey: 'CTRL', // Key that must be down for items to be selected
            avoidImplicitDeselect: false, // true - if you don't want to deselect items on outside click
            onMove: (evt) => {
                if($(evt.to).attr('id') === 'hidden') {
                    return !$(evt.dragged).hasClass("mandatory");
                }
            }
        });
        new Sortable(this.MODAL_CONTAINER_EL.find('#hidden').get(0), {
            group: 'shared',
            preventOnFilter: true,
            animation: 150,
            multiDrag: true,
            sort: false,
            selectedClass: "sortable-selected", // Class name for selected item
            multiDragKey: 'CTRL', // Key that must be down for items to be selected
            avoidImplicitDeselect: false, // true - if you don't want to deselect items on outside click
            onSort: (evt) => {
                const newList = [];
                $.each($(evt.srcElement).find('li'), (_k, el) => {
                    newList.push($(el).clone());
                });

                const toAppend = newList.sort((a, b) => {
                    if (a.text() < b.text()) {
                        return -1;
                    }
                    if (a.text() > b.text()) {
                        return 1;
                    }
                    return 0;
                });
                evt.srcElement.innerHTML = '';
                toAppend.forEach((el) => evt.srcElement.appendChild(el.get(0)));
            }
        });
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('#reset_section').unbind('click').on('click', (e) => {
            this.MODAL_CONTAINER_EL.find('#assigned').html(this.INITIAL_ASSIGNED_STATE);
            $.each(this.MODAL_CONTAINER_EL.find('#assigned li'), (k, el) => {
                $(el).attr('id', $(el).attr('id').replace('default§', ''));
            });
            this.MODAL_CONTAINER_EL.find('#hidden').html(this.INITIAL_HIDDEN_STATE);
            $.each(this.MODAL_CONTAINER_EL.find('#hidden li'), (k, el) => {
                $(el).attr('id', $(el).attr('id').replace('default§', ''));
            });
        });
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            let targetURL = app.CACHE.URL_AJAX + 'list_settings/edit/';
            let data = {
                'section': this.MODAL_CONTAINER_EL.find('input#section').val(),
                'new_settings': this.getEnabledColumns(),
            };

            if (this.MODAL_CONTAINER_EL.find('input#subsection').length > 0) {
                data.subsection = $('input#subsection').val();
            }

            AjaxSync({ url: targetURL, data, method: 'POST' }, { done: (res) => window.location.reload() });
        });
    }

    getEnabledColumns() {
        let json = {},
            listItems = this.MODAL_CONTAINER_EL.find('ul#assigned').children('li');
        $.each(listItems, (i, li) => {
            json[li.id] = {};
            json[li.id].position = i + 1;
        });

        return encodeURI(JSON.stringify(json));
    }
}
